/** @jsx jsx */
import { jsx, Heading } from "theme-ui"
import Layout from '../components/layout'
import AudacieusesGrid from '../components/audacieuses-grid'

export default function IndexPage(props) {

  return (
    <Layout
    seo={{
      title: `Collectif Swing - des femmes qui entreprennent sur Monnières (44)`,
      description: `Le Collectif Swing est un collectif qui regroupe des entrepreneuses basées à Monnières en Loire-Atlantique (44).`,

    }}>
      <section>
        <div sx={{ textAlign: 'center', mb: 5 }}>
          <Heading as="h1" variant="pageTitle" mb={3}>Swing, collectif d'audacieuses</Heading>
          <div sx={{ fontFamily:'caviar dreams, sans-serif', fontSize: [5, 6], color: 'secondary' }}>Des femmes qui entreprennent sur Monnières</div>
        </div>

        <AudacieusesGrid {...props} />
      </section>
    </Layout>
  )
}
