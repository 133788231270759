import { useState } from 'react';
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"
import { getImage } from "gatsby-plugin-image"
/** @jsx jsx */
import { jsx, Badge, Box, Card, Grid, Heading, Image, Select } from "theme-ui"
import { useCategoriesMetadata } from '../hooks/use-categories-metadata'

export default function AudacieusesGrid({ perPage, location }) {

  const { allMdx: { nodes: audacieuses } } = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          fileAbsolutePath: {regex: "/audacieuses/"}
        }
        sort: {
          fields: [frontmatter___name]
          order: ASC
        }
      ) {
        nodes {
          frontmatter {
            name
            category
            companyName
            job
            portrait {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  `)

  const [categoryId, setCategoryId] = useState(location.state?.categoryId ?? '')
  const { categories } = useCategoriesMetadata()
  const displayedAudacieuses = audacieuses
    .filter(audacieuse => !categoryId || categoryId === audacieuse.frontmatter.category)
    .sort((a, b) => a.frontmatter.name.localeCompare(b.frontmatter.name))

  function handleSelectChange(event) {
    window.history.replaceState({categoryId: event.target.value}, '')
    setCategoryId(event.target.value)
  }

  return (
    <div>
      <div sx={{ mb: 4, color: 'primary' }}>
        <Select aria-label="Filtrer par catégorie" value={categoryId} onChange={handleSelectChange} sx={{ fontFamily:'poppins, sans-serif'}}>
          <option value="">Toutes les catégories</option>
          {categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
        </Select>
      </div>

      <Grid gap={4} columns={[1, 2, 3, 4]} sx={{ gridAutoRows: ['auto', '1fr'], placeItems: 'center' }}>
      {
        displayedAudacieuses.map(audacieuse => {
          const image = getImage(audacieuse.frontmatter.portrait)

          return (
            <GatsbyLink key={audacieuse.frontmatter.name} to={`/${audacieuse.parent.name}/`} sx={{ display: 'block', height: '100%', color: 'inherit', textDecoration: 'none' }}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  height: '100%',
                  maxWidth: '300px'
                }}
              >
                <div sx={{ position: 'relative', width: '100%' }}>
                  <Badge sx={{ position: 'absolute', top: 2, left: 2 }}>{audacieuse.frontmatter.category}</Badge>
                  <div sx={{ bg: 'muted' }}>
                    <Image sx={{display: 'block'}}
                      width={image.width} height={image.height}
                      src={image.images.fallback.src} srcSet={image.images.fallback.srcSet} sizes={image.images.fallback.sizes}
                      alt=""
                    />
                  </div>
                </div>
                <Heading as="h2" sx={{ mt: 3, color: 'text' }}>{audacieuse.frontmatter.name}</Heading>
                <div sx={{ flex: '1 1 auto', mb: 2 }}>
                  { audacieuse.frontmatter.companyName &&
                    <div>{audacieuse.frontmatter.companyName}</div>
                  }
                  <em sx={{ color: 'primary' }}>{audacieuse.frontmatter.job}</em>
                </div>
                <Box sx={{color: 'secondary'}}>Voir le profil</Box>
              </Card>
            </GatsbyLink>
          )
        })
      }
      </Grid>
    </div>
  )
}
